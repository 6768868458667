import React from 'react';
import { ChromePicker } from 'react-color';
import { GradientPicker, AnglePicker } from 'react-linear-gradient-picker';
import 'react-linear-gradient-picker/dist/index.css';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputNumber from './InputNumber';
import { StyledInputWrapperBig } from './styled/AttributeInputs';
import { AngleIcon } from '../../assets/icons';

const GradientPickerWrapper = styled.div`
  .palette {
    display: ${(props) => (props.isGradient ? 'block' : 'none')};
  }
  .csh {
    margin-bottom: 5px;
    display: ${(props) => (props.isGradient ? 'block' : 'none')};
  }
`;
const StyledAngleWrapper = styled.div`
  align-items: center;
  margin-top: 10px;
  display: flex;
  justify-content: end;
  > .ai {
    display: none;
  }
  > .ap {
    flex: none;
    margin-right: 15px;
    box-sizing: border-box;
    background-color: #313131;
    border: 2px solid #747474;
    border-radius: 50%;
    display: ${(props) => (props.isGradient ? 'inline-block' : 'none')};
    position: relative;
    cursor: pointer;
  }

  .ap .apc {
    width: 6px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .ap .aph {
    width: 6px;
    height: 6px;
    background-color: #747474;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 4px;
    margin: auto;
    cursor: pointer;
  }
`;
const rgbToRgba = (rgb, a = 1) => rgb.replace('rgb(', 'rgba(').replace(')', `, ${a})`);

const WrappedChromePicker = ({ onSelect, ...rest }) => (
  <ChromePicker
    {...rest}
    color={rgbToRgba(rest.color, rest.opacity)}
    onChange={(c) => {
      const { r, g, b, a } = c.rgb;
      onSelect(`rgb(${r}, ${g}, ${b})`, a);
    }}
  />
);

const GradientColorPicker = ({ colorPickerData, onHandleDataChange, color }) => (
  <GradientPickerWrapper isGradient={colorPickerData.isGradient}>
    <GradientPicker
      {...{
        width: 241,
        maxStops: 20,
        minStops: 2,
        paletteHeight: 32,
        palette: color.palette || [
          { color: 'rgb(255,255,255)', opacity: 1, offset: 0, id: 1, active: true }
        ],
        showAnglePicker: false,
        onPaletteChange: (paletteSettings) => {
          if (colorPickerData.isGradient) {
            onHandleDataChange({ palette: paletteSettings, colorType: colorPickerData.id });
          }
          if (paletteSettings.length > 1 && !colorPickerData.isGradient) {
            const solidColorObject = paletteSettings.find((obj) => obj.active === true);
            onHandleDataChange({
              palette: new Array({ ...solidColorObject, offset: 0 })
            });
          }
          if (paletteSettings.length === 1 && !colorPickerData.isGradient) {
            onHandleDataChange({ palette: paletteSettings });
          }
        }
      }}>
      <WrappedChromePicker />
    </GradientPicker>

    {colorPickerData.id === 'linear' && (
      <StyledAngleWrapper isGradient={colorPickerData.isGradient}>
        <AnglePicker
          size={25}
          angle={color.angle}
          setAngle={(angle) => onHandleDataChange({ angle })}
        />
        <StyledInputWrapperBig removedBottomMargin>
          <AngleIcon />
          <InputNumber
            id='gradient-picker-angle'
            value={color.angle}
            min={0}
            max={360}
            onUpdate={(value) => {
              onHandleDataChange({ angle: value });
            }}
          />
        </StyledInputWrapperBig>
      </StyledAngleWrapper>
    )}
  </GradientPickerWrapper>
);
GradientColorPicker.propTypes = {
  onHandleDataChange: PropTypes.func.isRequired,
  color: PropTypes.object.isRequired,
  colorPickerData: PropTypes.object.isRequired
};
export default GradientColorPicker;
