/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect, useState, useCallback, Fragment, useMemo } from 'react';
import { ChromePicker } from 'react-color';
import { v4 as uuidv4 } from 'uuid';
import { connect, useSelector, useDispatch } from 'react-redux';

import { useClickOutside } from '../../assets/utils';
import {
  AlignLeftIcon,
  AlignRightIcon,
  AlignCenterIcon,
  LineHeightIcon,
  TextSizeIcon,
  AttributeClosedIcon,
  LightMode,
  DarkMode,
  SmallLineIcon,
  UppercaseIcon,
  LowercaseIcon,
  LetterSpacingIcon,
  VerticalAlignItemsStartIcon,
  VerticalAlignItemsCenterIcon,
  VerticalAlignItemsEndIcon
} from '../../assets/icons';
import { defaultFonts } from '../../assets/defaultFonts';
import convertRGBAToHex from '../../../utils/convertRGBAToHex';
import InputNumber from '../common/InputNumber';
import BasicAttributes from './BasicAttributes';
import BorderAttribute from './BorderAttribute';
import BoxShadowAttribute from './BoxShadowAttribute';
import Grid from '../../assets/transparent_grid.png';
import TextShadowAttribute from './TextShadowAttribute';
import TextPaddingAttribute from './TextPaddingAttribute';
import BaseButton from '../common/BaseButton';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import bytesToSize from '../../../utils/bytesToSize';
import prettyImageType from '../../../utils/prettyImageType';
import {
  updateLayerDCO,
  updateLayerImageScale,
  applyImageToAllFormats,
  applyVideoToAllFormats,
  applyTextToAllFormats,
  updateLayerPosition,
  updateLayerFormat,
  updateFormatLayerSettings
} from '../../redux/slices/template';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import {
  setIsEditingImage,
  setIsEditingOverlayImage,
  setImageToEdit,
  setIframeIsOpened
} from '../../redux/slices/editorSession';
import {
  StyledInputWrapperSmall,
  StyledInputWrapperBig,
  StyledSelectWide,
  StyledFlexSpaceBetween,
  StyledFlexCenter,
  StyledChromePickerWrapper,
  StyledColorPickerButton,
  StyledColorPickerBackground,
  StyledCheckbox,
  StyledInputLabelWrapper,
  StyledOpacityInputWrapper
} from '../common/styled/AttributeInputs';
import TextEditor from '../EditorCanvas/TextEditor';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';
import { flat } from '../../../helpers';

function RightMenu(props) {
  const {
    userFonts,
    googleFonts,
    onsetUserFonts,
    updateLayerFont,
    onIsTextEditingEnabled,
    isEditingTextEnabled,
    formats,
    toolbarRef,
    onIsReadonlyTextEnabled
  } = props;

  const dispatch = useDispatch();

  const { Checkboard } = require('react-color/lib/components/common');
  const capturePosthogData = usePosthogCapture();
  const dcoSelection = useSelector((state) => state.dco.dcoSelection);
  const { activeLayer, selectedFormatId, editorType, studio } = useSelector(
    (state) => state.editorSession
  );
  const { presentLayers, selectedFonts } = studio;

  function onUpdateLayerFormat(layerId, formatData) {
    function checkForNullValues(list) {
      for (const [key, value] of Object.entries(list)) {
        if (value === '') {
          list[key] = '0';
          continue;
        }

        if (typeof value === 'object') {
          checkForNullValues(value);
        }
      }
    }

    checkForNullValues(formatData);

    if ('x' in formatData || 'y' in formatData) {
      dispatch(updateLayerPosition(selectedFormatId, layerId, formatData));
    } else {
      dispatch(updateLayerFormat(selectedFormatId, layerId, formatData));
    }
  }

  function getDefaultFontWeight(font_weight, font_style) {
    let value = 'Regular';

    if (font_weight === 'Bold') {
      if (font_style === 'Italic') {
        value = 'Bold Italic';
      }
      if (font_style === 'Normal') {
        value = 'Bold';
      }
    }
    if (font_weight === 'Regular') {
      if (font_style === 'Italic') {
        value = 'Italic';
      }
      if (font_style === 'Normal') {
        value = 'Regular';
      }
    }

    return value;
  }

  function handleSelectedFont(layerId, fontName) {
    let fontObject = {};
    // check if the font already exists in the selected fonts
    const existingFont = selectedFonts.find((font) => font.name === fontName);
    if (existingFont) {
      fontObject = existingFont;
    }
    // don't generate new uuid if it does
    if (!existingFont) {
      const uuid = uuidv4();
      // check if it's a web-safe font
      const defaultFont = defaultFonts.find((font) => font.name === fontName);
      if (defaultFont) {
        fontObject = {
          uuid,
          reference_type: 'web_safe',
          name: defaultFont.name,
          value: defaultFont.value
        };
      }

      // check if it's a custom font
      const customFont =
        userFonts.length > 0 && userFonts.find((font) => font.font_name === fontName);
      if (customFont) {
        fontObject = {
          uuid,
          reference_type: 'external',
          source: customFont.s3_url,
          name: customFont.font_name,
          weight: customFont.font_weight,
          style: customFont.font_style
        };
      }

      // check if it's a google font
      const googleFont = googleFonts && googleFonts.find((font) => font.family === fontName);
      if (googleFont) {
        fontObject = {
          uuid,
          reference_type: 'google',
          weights: googleFont.variants,
          name: googleFont.family
        };
      }
    }

    updateLayerFont(layerId, selectedFormatId, fontObject);
  }

  // justify content
  const HORIZONTAL_ALIGN_TEXT_DATA = [
    {
      id: 'text_alignment_left',
      direction: 'start',
      textAlignmentDirection: 'left',
      posthogInputId: 'right-submenu-text-align-left',
      icon: <AlignLeftIcon />
    },
    {
      id: 'text_alignment_center',
      direction: 'center',
      textAlignmentDirection: 'center',
      posthogInputId: 'right-submenu-edit-text-align-center',
      icon: <AlignCenterIcon />
    },
    {
      id: 'text_alignment_right',
      direction: 'end',
      textAlignmentDirection: 'right',
      posthogInputId: 'right-submenu-text-align-right',
      icon: <AlignRightIcon />
    }
  ];

  const UPPERCASE_LOWERCASE_DATA = [
    {
      id: 'reset_lowercase_or_uppercase',
      title: 'As typed',
      type: 'none',
      posthogInputId: 'right-submenu-text-reset-uppercase-lowercase',
      icon: <SmallLineIcon />
    },
    {
      id: 'text_uppercase',
      type: 'uppercase',
      title: 'Uppercase',
      posthogInputId: 'right-submenu-text-set-uppercase',
      icon: <UppercaseIcon />
    },
    {
      id: 'text_lowercase',
      type: 'lowercase',
      title: 'Lowercase',
      posthogInputId: 'right-submenu-text-set-lowercase',
      icon: <LowercaseIcon />
    }
  ];

  // align items
  const VERTICAL_ALIGN_TEXT_DATA = [
    {
      id: 'vertical_align_start',
      direction: 'start',
      posthogInputId: 'right-submenu-text-vertical-align-start',
      icon: <VerticalAlignItemsStartIcon />
    },
    {
      id: 'vertical_align_center',
      direction: 'center',
      posthogInputId: 'right-submenu-text-vertical-align-center',
      icon: <VerticalAlignItemsCenterIcon />
    },
    {
      id: 'vertical_align_end',
      direction: 'end',
      posthogInputId: 'right-submenu-text-vertical-align-end',
      icon: <VerticalAlignItemsEndIcon />
    }
  ];

  const [attributesFormSection, setAttributesFormSection] = useState({
    fontUploadeIframeOpened: true,
    textPropertiesOpened: true,
    textContentOpened: true,
    dcoContentOpened: true,
    imageOpened: true,
    imageBehaviourOpened: true,
    layerSettingsOpened: true,
    backgroundColorPickerOpened: false,
    fontColorPickerOpened: false
  });

  const fontColorPickerRef = useRef();
  const fontColorPickerButtonRef = useRef();
  const textEditorRef = useRef();

  useClickOutside(
    fontColorPickerRef,
    () => {
      setAttributesFormSection({
        ...attributesFormSection,
        fontColorPickerOpened: false
      });
    },
    [fontColorPickerButtonRef]
  );

  const [shiftPressed, onShiftPressed] = useState(false);

  function getImageScale(layer) {
    let scale = 1;
    if ('imageState' in layer.settings) {
      if ('targetSize' in layer.settings.imageState) {
        if ('scale' in layer.settings.imageState.targetSize) {
          scale = layer.settings.imageState.targetSize.scale;
        }
      }
    }
    return scale;
  }

  const [showLineHeightInput, setShowLineHeightInput] = useState(false);

  const [editorLightMode, setEditorLightMode] = useState(true);

  function getLayerFont(fontId) {
    const fontObject =
      selectedFonts.length > 0 && selectedFonts.find((font) => font.uuid === fontId);
    let fontFamily;
    if (fontObject) {
      if (
        fontObject.reference_type === 'web_safe' &&
        !fontObject.value &&
        fontObject.name === 'Arial'
      ) {
        fontFamily = defaultFonts.find((font) => font.name === fontObject.name).value;
      } else
        fontFamily = fontObject.reference_type === 'web_safe' ? fontObject.value : fontObject.name;
    }
    return fontFamily;
  }

  const isGoogleStudioDCO = dcoSelection.dynamicSource?.addon === 'google-studio';

  const dynamicSourceKeys = isGoogleStudioDCO
    ? dcoSelection.dynamicSource?.keys?.keys
    : dcoSelection.dynamicSource?.keys;

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === 'Shift') {
          onShiftPressed(true);
        }
      }}
      onKeyUp={(e) => {
        if (e.key === 'Shift') {
          onShiftPressed(false);
        }
      }}>
      {flat(presentLayers).map(
        (layer) =>
          layer.uuid === activeLayer && (
            <Fragment key={layer.uuid}>
              {layer.type === 'image' && (
                <>
                  {editorType === 'banner' && (
                    <StyledSectionWrap>
                      <StyledFlexCenter height='100%' boxWidth='100%' alignTop>
                        <BaseButton
                          style={{
                            backgroundColor: '#4792E2',
                            color: 'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            width: 'auto'
                          }}
                          id='right-menu-open-apply-image-to-all-formats-button'
                          onClick={(e) => {
                            dispatch(
                              applyImageToAllFormats(
                                layer.uuid,
                                selectedFormatId,
                                layer.settings.source
                              )
                            );
                          }}
                          text='Apply image to all formats'
                        />
                      </StyledFlexCenter>
                    </StyledSectionWrap>
                  )}
                  <ToggleRevealSectionButton
                    id='right-submenu-edit-open-close-box-shadow-section'
                    handleOnClick={() => {
                      setAttributesFormSection({
                        ...attributesFormSection,
                        imageOpened: !attributesFormSection.imageOpened
                      });
                    }}
                    isOpened={attributesFormSection.imageOpened}>
                    <p className='color-white'>Image content</p>
                  </ToggleRevealSectionButton>
                  {attributesFormSection.imageOpened && (
                    <>
                      <StyledSectionWrap>
                        {layer.settings.source.content_type !== 'image/svg+xml' && (
                          <StyledInputLabelWrapper>
                            <p>File size</p>
                            <StyledOpacityInputWrapper>
                              <input
                                type='range'
                                value={(getImageScale(layer) * 100).toFixed(0)}
                                step='5'
                                min='50'
                                max='200'
                                id='right-submenu-edit-opacity'
                                onChange={(e) => {
                                  const imageState =
                                    'imageState' in layer.settings
                                      ? {
                                          ...layer.settings.imageState,
                                          targetSize: {
                                            ...layer.settings.imageState.targetSize,
                                            scale: e.target.value / 100
                                          }
                                        }
                                      : {
                                          targetSize: {
                                            width: layer.width,
                                            height: layer.height,
                                            scale: e.target.value / 100
                                          }
                                        };

                                  dispatch(
                                    updateLayerImageScale(
                                      layer.uuid,
                                      selectedFormatId,
                                      imageState,
                                      false
                                    )
                                  );
                                }}
                              />
                              <div>
                                <p className='color-white'>
                                  {(getImageScale(layer) * 100).toFixed(0)}%
                                </p>
                              </div>
                            </StyledOpacityInputWrapper>
                          </StyledInputLabelWrapper>
                        )}
                        <div
                          className='image-option'
                          style={{
                            backgroundImage: `url(${layer.settings.source.thumbnail_url})`
                          }}>
                          <Checkboard />
                        </div>
                        <div>
                          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <span style={{ fontWeight: '400' }}>Width:</span>
                              <span>{`${(layer.format.width * getImageScale(layer)).toFixed(
                                0
                              )}`}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <span style={{ fontWeight: '400' }}>Height:</span>
                              <span>{`${(layer.format.height * getImageScale(layer)).toFixed(
                                0
                              )}`}</span>
                            </div> */}
                          <StyledInputWrapperBig disabled>
                            <span>Size</span>
                            {layer.settings.source.content_type === 'image/svg+xml' ? (
                              <span>{bytesToSize(layer.settings.source.size)}</span>
                            ) : (
                              <span>
                                {typeof layer.settings.source === 'object' &&
                                'overwrittenBase64' in layer.settings.source &&
                                selectedFormatId in layer.settings.source.overwrittenBase64 &&
                                layer.id in
                                  layer.settings.source.overwrittenBase64[selectedFormatId]
                                  ? bytesToSize(
                                      layer.settings.source.overwrittenBase64[selectedFormatId][
                                        layer.id
                                      ].size
                                    )
                                  : 'Calculating...'}
                              </span>
                            )}
                          </StyledInputWrapperBig>
                          <StyledInputWrapperBig disabled>
                            <span style={{ fontWeight: '400' }}>Type</span>
                            <span style={{ fontWeight: '400' }}>
                              {typeof layer.settings.source === 'object' &&
                              'content_type' in layer.settings.source
                                ? prettyImageType(layer.settings.source.content_type).toUpperCase()
                                : ''}
                            </span>
                          </StyledInputWrapperBig>
                          {layer.settings.source.content_type !== 'image/svg+xml' && (
                            <BaseButton
                              id='right-menu-edit-image'
                              text='Edit Image'
                              style={{
                                backgroundColor: '#4792E2',
                                height: '23px',
                                marginTop: '8px',
                                marginBottom: '0px',
                                width: 100
                              }}
                              onClick={(e) => {
                                if (layer.uuid === activeLayer && layer.type === 'image') {
                                  if ('url' in layer.settings.source) {
                                    if (layer.settings.source.content_type !== 'image/svg+xml') {
                                      dispatch(setImageToEdit(layer));
                                      dispatch(setIsEditingImage(true));
                                      dispatch(setIsEditingOverlayImage(false));
                                    }
                                  }
                                }
                              }}
                            />
                          )}
                        </div>

                        {(dcoSelection.parameterSourceIncluded ||
                          dcoSelection.dynamicSourceIncluded) && (
                          <>
                            <p
                              style={{
                                margin: '15px 0px 5px 0px',
                                fontSize: '13px'
                              }}>
                              Data source field
                            </p>
                            <StyledSelectWide
                              onChange={(e) => {
                                dispatch(
                                  updateLayerDCO(layer.uuid, {
                                    dynamic_key: e.target.value,
                                    dynamic_uuid: e.target[e.target.selectedIndex].id
                                  })
                                );
                              }}
                              defaultValue={layer.dynamic.dynamic_key}>
                              <option value='None' id=''>
                                None
                              </option>

                              {dcoSelection.dynamicSourceIncluded && !isGoogleStudioDCO && (
                                <>
                                  <optgroup label='' />
                                  <optgroup label='Dynamic' />
                                  {dynamicSourceKeys.map((parameter) => (
                                    <option
                                      key={`${parameter}`}
                                      value={parameter}
                                      id={dcoSelection.dynamicSource.id}>
                                      {parameter}
                                    </option>
                                  ))}
                                </>
                              )}

                              {dcoSelection.dynamicSourceIncluded && isGoogleStudioDCO && (
                                <>
                                  {Object.entries(
                                    dynamicSourceKeys.reduce((acc, item) => {
                                      const [key, value] = item.split(/\[\d\]\./i);
                                      acc[key] = acc[key]
                                        ? [...acc[key], { short: value, full: item }]
                                        : [{ short: value, full: item }];
                                      return acc;
                                    }, {})
                                  ).map(([key, value]) => (
                                    <>
                                      <optgroup label='' />
                                      <optgroup label={key} />
                                      {value.map(({ short, full }) => (
                                        <option
                                          key={`${full}`}
                                          value={full}
                                          id={dcoSelection.dynamicSource.id}>
                                          {short}
                                        </option>
                                      ))}
                                    </>
                                  ))}
                                </>
                              )}

                              {dcoSelection.parameterSourceIncluded && (
                                <>
                                  <optgroup label='' />
                                  <optgroup label='Parameters' />
                                  {dcoSelection.parameterSource.keys.map((parameter) => (
                                    <option
                                      key={`${parameter.name}`}
                                      value={parameter.name}
                                      id={dcoSelection.parameterSource.id}>
                                      {parameter.name}
                                    </option>
                                  ))}
                                </>
                              )}
                              <AttributeClosedIcon />
                            </StyledSelectWide>
                          </>
                        )}
                      </StyledSectionWrap>
                    </>
                  )}
                </>
              )}

              {layer.type === 'video' && editorType === 'banner' && (
                <StyledSectionWrap>
                  <StyledFlexCenter height='100%' boxWidth='100%' alignTop>
                    <BaseButton
                      style={{
                        backgroundColor: '#4792E2',
                        color: 'white',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: 'auto'
                      }}
                      id='right-menu-open-apply-image-to-all-formats-button'
                      onClick={(e) => {
                        dispatch(applyVideoToAllFormats(layer.uuid, selectedFormatId));
                      }}
                      text='Apply video to all formats'
                    />
                  </StyledFlexCenter>
                </StyledSectionWrap>
              )}
              <BasicAttributes
                layer={layer}
                onUpdateLayerFormat={onUpdateLayerFormat}
                shiftPressed={shiftPressed}
              />
              {layer.type === 'text' && (
                <>
                  <ToggleRevealSectionButton
                    id='right-submenu-edit-open-close-text-properties-section'
                    handleOnClick={() => {
                      capturePosthogData({
                        event: 'toggle-open-close-menu',
                        type: 'edit',
                        menu: 'right',
                        menu_type: 'edit',
                        submenu: 'text-properties',
                        is_open: !attributesFormSection.textPropertiesOpened
                      });
                      setAttributesFormSection({
                        ...attributesFormSection,
                        textPropertiesOpened: !attributesFormSection.textPropertiesOpened
                      });
                    }}
                    isOpened={attributesFormSection.textPropertiesOpened}>
                    <p className='color-white'>Text Properties</p>
                  </ToggleRevealSectionButton>
                  {attributesFormSection.textPropertiesOpened && (
                    <>
                      <StyledSectionWrap>
                        <StyledSelectWide
                          id='right-submenu-edit-font-family'
                          onMouseLeave={() => onIsReadonlyTextEnabled(null)}
                          onClick={(e) => {
                            onIsTextEditingEnabled(layer.id);
                            onIsReadonlyTextEnabled(true);
                          }}
                          onChange={(e) => {
                            handleSelectedFont(layer.uuid, e.target.value);
                            onIsReadonlyTextEnabled(null);
                          }}
                          defaultValue={
                            selectedFonts.find((font) => font.uuid === layer.settings?.font)?.name
                          }>
                          {userFonts.length > 0 &&
                            userFonts.map((userFont, i) => (
                              <option value={userFont.font_name} key={i}>
                                {`${userFont.font_name} (private)`}
                              </option>
                            ))}
                          {defaultFonts.map((defaultFont, i) => (
                            <option value={defaultFont.name} key={i}>
                              {defaultFont.name}
                            </option>
                          ))}

                          {googleFonts &&
                            googleFonts.map((googleFont, i) => (
                              <option value={googleFont.family} key={i}>
                                {googleFont.family}
                              </option>
                            ))}
                        </StyledSelectWide>

                        <StyledSelectWide
                          id='right-submenu-edit-font-weight'
                          onMouseLeave={() => onIsReadonlyTextEnabled(null)}
                          onClick={(e) => {
                            onIsTextEditingEnabled(layer.id);
                            onIsReadonlyTextEnabled(true);
                          }}
                          onChange={(e) => {
                            const v = e.target.value;
                            onIsReadonlyTextEnabled(null);
                            onUpdateLayerFormat(layer.uuid, {
                              font_weight: ['Bold', 'Bold Italic'].includes(v) ? 'Bold' : 'Regular',
                              font_style: ['Italic', 'Bold Italic'].includes(v)
                                ? 'Italic'
                                : 'Normal'
                            });
                          }}
                          defaultValue={getDefaultFontWeight(
                            layer.format.font_weight,
                            layer.format.font_style
                          )}>
                          {['Regular', 'Bold', 'Italic', 'Bold Italic'].map((variant, i) => (
                            <option value={variant} key={i}>
                              {variant}
                            </option>
                          ))}
                        </StyledSelectWide>

                        <StyledInputWrapperBig onClick={() => onIsTextEditingEnabled(layer.id)}>
                          <TextSizeIcon />
                          <InputNumber
                            id='right-submenu-edit-font-size'
                            className='attributes-input'
                            min={0}
                            value={layer.format.font_size}
                            defaultValue={15}
                            onUpdate={(value) =>
                              onUpdateLayerFormat(layer.uuid, { font_size: value })
                            }
                          />
                        </StyledInputWrapperBig>
                        {/* HORIZONTAL ALIGN TEXT - JUSTIFY CONTENT */}
                        <StyledFlexSpaceBetween
                          bottomMargin='8px'
                          textalignment
                          onClick={(e) => onIsTextEditingEnabled(layer.id)}>
                          {HORIZONTAL_ALIGN_TEXT_DATA.map((button) => (
                            <StyledInputWrapperSmall
                              id={button.posthogInputId}
                              key={button.id}
                              style={{
                                backgroundColor:
                                  layer.format.align_items === button.direction
                                    ? '#747474'
                                    : '#313131'
                              }}
                              onClick={() => {
                                onUpdateLayerFormat(layer.uuid, {
                                  align_items: button.direction,
                                  text_alignment: button.textAlignmentDirection
                                });
                              }}>
                              {button.icon}
                            </StyledInputWrapperSmall>
                          ))}
                        </StyledFlexSpaceBetween>
                        {/* LINE HEIGHT */}
                        <StyledInputWrapperBig onClick={(e) => onIsTextEditingEnabled(layer.id)}>
                          <LineHeightIcon />
                          {(layer.format.line_height === 'normal' ||
                            layer.format.line_height === 'inherit') &&
                            !showLineHeightInput && (
                              <div
                                style={{ width: '100%', textAlign: 'right' }}
                                id='right-submenu-edit-line-height'
                                onClick={() => setShowLineHeightInput(true)}>
                                Auto
                              </div>
                            )}
                          {(typeof layer.format.line_height !== 'string' ||
                            showLineHeightInput) && (
                            <InputNumber
                              className='attributes-input'
                              id='right-submenu-edit-line-height'
                              min={0}
                              value={
                                layer.format.line_height === 'normal' ||
                                layer.format.line_height === 'inherit'
                                  ? layer.format.font_size
                                  : layer.format.line_height
                              }
                              defaultValue='normal'
                              onUpdate={(value) => {
                                setShowLineHeightInput(false);
                                onUpdateLayerFormat(layer.uuid, { line_height: value });
                              }}
                            />
                          )}
                        </StyledInputWrapperBig>
                        {/* VERTICAL ALIGN ITEMS */}
                        <StyledFlexSpaceBetween
                          bottomMargin='8px'
                          textalignment
                          onClick={(e) => onIsTextEditingEnabled(layer.id)}>
                          {VERTICAL_ALIGN_TEXT_DATA.map((button) => (
                            <StyledInputWrapperSmall
                              id={button.posthogInputId}
                              key={button.id}
                              style={{
                                backgroundColor:
                                  layer.format.justify_content &&
                                  layer.format.justify_content === button.direction
                                    ? '#747474'
                                    : layer.format.justify_content === undefined &&
                                      button.direction === 'start'
                                    ? '#747474'
                                    : '#313131'
                              }}
                              onClick={() => {
                                onUpdateLayerFormat(layer.uuid, {
                                  justify_content: button.direction
                                });
                              }}>
                              {button.icon}
                            </StyledInputWrapperSmall>
                          ))}
                        </StyledFlexSpaceBetween>
                        {/* LETTER SPACING */}
                        <StyledInputWrapperBig
                          bigBottomMargin
                          onClick={() => onIsTextEditingEnabled(layer.id)}>
                          <LetterSpacingIcon />
                          <InputNumber
                            id='right-submenu-edit-letter-spacing'
                            className='attributes-input'
                            min={0}
                            value={layer.format.letter_spacing || 0}
                            defaultValue={0}
                            onUpdate={(value) =>
                              onUpdateLayerFormat(layer.uuid, { letter_spacing: value })
                            }
                          />
                        </StyledInputWrapperBig>
                        {/* UPPERCASE LOWERCASE */}
                        <StyledFlexSpaceBetween
                          bottomMargin='18px'
                          textalignment
                          onClick={(e) => onIsTextEditingEnabled(layer.id)}>
                          {UPPERCASE_LOWERCASE_DATA.map((button) => (
                            <StyledInputWrapperSmall
                              id={button.posthogInputId}
                              key={button.id}
                              title={button.title}
                              style={{
                                backgroundColor:
                                  layer.format.text_transform &&
                                  layer.format.text_transform === button.type
                                    ? '#747474'
                                    : layer.format.text_transform === undefined &&
                                      button.type === 'none'
                                    ? '#747474'
                                    : '#313131'
                              }}
                              onClick={() =>
                                onUpdateLayerFormat(layer.uuid, {
                                  text_transform: button.type
                                })
                              }>
                              {button.icon}
                            </StyledInputWrapperSmall>
                          ))}
                        </StyledFlexSpaceBetween>
                        {/* COLORPICKER */}
                        <StyledFlexSpaceBetween bottomMargin='18px'>
                          <StyledColorPickerBackground>
                            <img
                              style={{
                                height: '23px',
                                width: '23px',
                                position: 'absolute',
                                borderRadius: '3px'
                              }}
                              alt='Zuuvi grid icon'
                              src={Grid}
                            />
                            <StyledColorPickerButton
                              ref={fontColorPickerButtonRef}
                              type='button'
                              style={{
                                backgroundColor: layer.format.font_color
                              }}
                              id='right-submenu-edit-open-background-colorpicker-font-color'
                              onClick={() => {
                                setAttributesFormSection({
                                  ...attributesFormSection,
                                  fontColorPickerOpened:
                                    !attributesFormSection.fontColorPickerOpened
                                });
                              }}></StyledColorPickerButton>
                          </StyledColorPickerBackground>
                          <div>
                            <p className='input-text'>
                              {layer.format.font_color
                                ? convertRGBAToHex(layer.format.font_color, false)
                                : ''}
                            </p>
                          </div>
                        </StyledFlexSpaceBetween>

                        {/* when we implement vertical align the button will be visible all the time */}
                        {!attributesFormSection.fontColorPickerOpened ? (
                          <BaseButton
                            id='upload-font'
                            text='Upload Font'
                            secondary
                            style={{
                              width: 100,
                              height: 25,
                              marginBottom: '18px'
                            }}
                            onClick={() => {
                              capturePosthogData({
                                event: 'open-upload-font-iframe',
                                type: 'font',
                                menu: 'right',
                                submenu: 'edit'
                              });
                              dispatch(setIframeIsOpened(true));
                            }}
                          />
                        ) : (
                          <StyledChromePickerWrapper ref={fontColorPickerRef} removedTopMargin>
                            <ChromePicker
                              color={layer.format.font_color}
                              onChange={(color) => {
                                const { r, g, b, a } = color.rgb;
                                return onUpdateLayerFormat(layer.uuid, {
                                  font_color: `rgba(${r}, ${g}, ${b}, ${a})`
                                });
                              }}
                            />
                          </StyledChromePickerWrapper>
                        )}
                        {/* TODO: MAKE STYLED COMPONENT */}
                        <div
                          style={{
                            width: 'calc(100% - 20px)',
                            height: '100%'
                          }}>
                          <TextEditor
                            outerScale={1.0}
                            bannerOverflowHidden={false}
                            textEditorRef={textEditorRef}
                            toolbarRef={toolbarRef}
                            readOnly={isEditingTextEnabled}
                            toolbarEnabled
                            location='editMenu'
                            layerObject={layer}
                            isEditorMenu
                            style={{
                              width: '100%',
                              minHeight: '47px',
                              maxHeight: '68px',
                              border: '1px solid rgba(116, 116, 116, 1)',
                              backgroundColor: editorLightMode
                                ? 'rgba(241, 241, 241, 1)'
                                : 'rgba(49, 49, 49, 1)',
                              borderRadius: '4px',
                              padding: '4px 9px',
                              overflowY: 'scroll',
                              // After
                              fontFamily: getLayerFont(layer.settings.font),
                              // textAlign: layer.format.text_alignment,
                              fontWeight: layer.format.font_weight === 'Bold' ? 700 : 400,
                              fontStyle: layer.format.font_style,
                              // pointerEvents: (isLocked || isHidden || isIgnored) && 'none',
                              outline: 'none',
                              color: layer.format.font_color,
                              fontSize: '13px'
                              // lineHeight:
                              //   layer.format.line_height === 'normal'
                              //     ? 'normal'
                              //     : `${layer.format.line_height}px`
                            }}
                            doubleClickedText
                          />
                          <div
                            style={{
                              position: 'relative',
                              right: -217,
                              bottom: 19,
                              cursor: 'pointer',
                              width: '0px',
                              height: '0px'
                            }}
                            onClick={() => setEditorLightMode(!editorLightMode)}>
                            {editorLightMode ? <LightMode /> : <DarkMode />}
                          </div>
                        </div>
                        <StyledFlexCenter height='100%' boxWidth='100%' alignTop>
                          <BaseButton
                            style={{
                              backgroundColor: '#4792E2',
                              color: 'white',
                              paddingLeft: '20px',
                              paddingRight: '20px',
                              width: 'auto',
                              marginTop: '18px'
                            }}
                            id='right-menu-open-apply-text-to-all-formats-button'
                            onClick={() => {
                              dispatch(
                                applyTextToAllFormats(
                                  layer.uuid,
                                  selectedFormatId,
                                  layer.settings.text
                                )
                              );
                            }}
                            text='Apply text to all formats'
                          />
                        </StyledFlexCenter>
                      </StyledSectionWrap>
                    </>
                  )}

                  <TextPaddingAttribute layer={layer} onUpdateLayerFormat={onUpdateLayerFormat} />
                  <TextShadowAttribute layer={layer} onUpdateLayerFormat={onUpdateLayerFormat} />
                </>
              )}

              {/* BORDER */}
              <BorderAttribute layer={layer} onUpdateLayerFormat={onUpdateLayerFormat} />

              {/* BOX SHADOW  */}
              <BoxShadowAttribute layer={layer} onUpdateLayerFormat={onUpdateLayerFormat} />

              <ToggleRevealSectionButton
                id='right-submenu-edit-open-close-layer-settings-section'
                handleOnClick={() => {
                  capturePosthogData({
                    event: 'toggle-open-close-menu',
                    type: 'edit',
                    menu: 'right',
                    menu_type: 'edit',
                    submenu: 'layer-settings',
                    is_open: !attributesFormSection.layerSettingsOpened
                  });
                  setAttributesFormSection({
                    ...attributesFormSection,
                    layerSettingsOpened: !attributesFormSection.layerSettingsOpened
                  });
                }}
                isOpened={attributesFormSection.layerSettingsOpened}>
                <p className='color-white'>Ignore in Formats</p>
              </ToggleRevealSectionButton>
              {attributesFormSection.layerSettingsOpened && (
                <StyledSectionWrap>
                  {formats.map((format) => (
                    <StyledFlexSpaceBetween key={format.id} boxWidth='100%' alignTop>
                      <StyledCheckbox
                        id='right-submenu-ignore-in-formats'
                        type='checkbox'
                        checked={
                          format?.object_data?.layers[layer.id]?.settings?.ignored
                            ? format?.object_data?.layers[layer.id]?.settings?.ignored
                            : false
                        }
                        onChange={(e) => {
                          dispatch(
                            updateFormatLayerSettings(format.id, layer.uuid, {
                              ignored: e.target.checked
                            })
                          );
                        }}
                      />{' '}
                      <p className=' color-white'>{`Ignore in ${format.title}`}</p>
                    </StyledFlexSpaceBetween>
                  ))}
                </StyledSectionWrap>
              )}
            </Fragment>
          )
      )}
    </div>
  );
}

RightMenu = connect((state) => ({
  formats: state.template.formats
}))(RightMenu);

export default RightMenu;
