import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '../../assets/transparent_grid.png';
import { ChromePicker } from 'react-color';
import InputNumber from '../common/InputNumber';
import { useClickOutside } from '../../assets/utils';
import convertRGBAToHex from '../../../utils/convertRGBAToHex';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import {
  StyledColorPickerButton,
  StyledFlexSpaceBetween,
  StyledInputWrapperBig,
  StyledColorPickerBackground,
  StyledChromePickerWrapper,
  StyledSpan
} from '../common/styled/AttributeInputs';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';

function TextShadowAttribute(props) {
  const { layer, onUpdateLayerFormat } = props;
  const capturePosthogData = usePosthogCapture();

  const [attributeOpen, setAttributeOpen] = useState(true);
  const [textShadowPickerOpened, setTextShadowPickerOpened] = useState(false);
  const textShadowPickerRef = useRef();
  const textShadowPickerButtonRef = useRef();

  useClickOutside(
    textShadowPickerRef,
    () => {
      setTextShadowPickerOpened(false);
    },
    [textShadowPickerButtonRef]
  );

  const TEXT_SHADOW_DATA = [
    {
      id: 'x',
      inputText: 'X',
      posthogInputId: 'right-submenu-edit-text-shadow-x'
    },
    {
      id: 'y',
      inputText: 'Y',
      posthogInputId: 'right-submenu-edit-text-shadow-y'
    },
    {
      id: 'blur',
      inputText: 'Blur',
      posthogInputId: 'right-submenu-edit-text-shadow-blur',
      removedBottomMargin: true,
      min: 0
    }
  ];

  const handleChange = (input, value) => {
    onUpdateLayerFormat(layer.uuid, {
      text_shadow: {
        ...layer.format.text_shadow,
        [input.id]: value
      }
    });
  };
  return (
    <>
      <ToggleRevealSectionButton
        id='right-submenu-edit-open-close-text-shadow-section'
        handleOnClick={() => {
          capturePosthogData({
            event: 'toggle-open-close-menu',
            type: 'edit',
            menu: 'right',
            menu_type: 'edit',
            submenu: 'text-shadow',
            is_open: !attributeOpen
          });
          setAttributeOpen(!attributeOpen);
        }}
        isOpened={attributeOpen}>
        <p className='color-white'>Drop Text Shadow</p>
      </ToggleRevealSectionButton>
      {attributeOpen && (
        <StyledSectionWrap>
          <>
            {TEXT_SHADOW_DATA.map((input) => (
              <StyledInputWrapperBig key={input.id} removedBottomMargin={input.removedBottomMargin}>
                <p className='input-text'>{input.inputText}</p>
                <InputNumber
                  key={input.id}
                  id={input.posthogInputId}
                  className={`attributes-input ${input.id}`}
                  min={input.min}
                  value={layer.format.text_shadow[`${input.id}`]}
                  onUpdate={(value) => handleChange(input, value)}
                />
                <StyledSpan>px</StyledSpan>
              </StyledInputWrapperBig>
            ))}

            <StyledFlexSpaceBetween>
              <StyledColorPickerBackground>
                <img
                  style={{
                    height: '23px',
                    width: '23px',
                    position: 'absolute',
                    borderRadius: '3px'
                  }}
                  alt='Zuuvi grid icon'
                  src={Grid}
                />
                <StyledColorPickerButton
                  ref={textShadowPickerButtonRef}
                  type='button'
                  style={{
                    backgroundColor: layer.format.text_shadow.color
                  }}
                  id='right-submenu-edit-open-background-colorpicker-font-color'
                  onClick={() =>
                    setTextShadowPickerOpened(!textShadowPickerOpened)
                  }></StyledColorPickerButton>
              </StyledColorPickerBackground>
              <div>
                <p className='input-text'>
                  {layer.format.text_shadow.color
                    ? convertRGBAToHex(layer.format.text_shadow.color, false)
                    : ''}
                </p>
              </div>
            </StyledFlexSpaceBetween>
          </>
          {textShadowPickerOpened && (
            <StyledChromePickerWrapper ref={textShadowPickerRef}>
              <ChromePicker
                color={layer.format.text_shadow.color}
                onChange={(color) => {
                  const { r, g, b, a } = color.rgb;
                  return onUpdateLayerFormat(layer.uuid, {
                    text_shadow: {
                      ...layer.format.text_shadow,
                      color: `rgba(${r}, ${g}, ${b}, ${a})`
                    }
                  });
                }}
              />
            </StyledChromePickerWrapper>
          )}
        </StyledSectionWrap>
      )}
    </>
  );
}

TextShadowAttribute.propTypes = {
  layer: PropTypes.object.isRequired,
  onUpdateLayerFormat: PropTypes.func.isRequired
};

export default TextShadowAttribute;
