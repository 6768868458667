import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import GradientColorPicker from './GradientColorPicker';
import { useClickOutside } from '../../assets/utils';
import {
  StyledColorPickerButton,
  StyledFlexSpaceBetween,
  StyledColorPickerBackground,
  StyledChromePickerWrapper,
  StyledSelectWide
} from './styled/AttributeInputs';
import { createColorString } from '../../../helpers';
import Grid from '../../assets/transparent_grid.png';

function ColorPicker({ color, onChange }) {
  const [colorPickerOpened, setColorPickerOpened] = useState(false);
  const [originalColorType, setOriginalColorType] = useState(false);
  const colorOptions = [
    { title: 'Solid', id: 'solid', isGradient: false },
    { title: 'Linear', id: 'linear', isGradient: true },
    { title: 'Radial', id: 'radial', isGradient: true }
  ];
  const [colorPickerData, setColorPickerData] = useState(
    colorOptions.find((option) => option.id === color.colorType) || colorOptions[0]
  );
  const colorPickerRef = useRef();
  const colorpickerButtonRef = useRef();

  const handleClick = () => {
    setOriginalColorType(color.colorType);
    setColorPickerOpened(true);
  };

  // eslint-disable-next-line no-shadow
  const handleDataChange = (data) => {
    onChange(data);
  };

  useClickOutside(
    colorPickerRef,
    () => {
      // if user closes colorpicker as solid without actually making the change to solid color the colorType will change back to the original one
      if (color.colorType === 'solid' && color.palette.length !== 1) {
        // if the original color is solid then use linear as default
        setColorPickerData(
          originalColorType !== 'solid'
            ? colorOptions.find((option) => option.id === originalColorType)
            : { title: 'Linear', id: 'linear', isGradient: true }
        );
        handleDataChange({
          colorType: originalColorType !== 'solid' ? originalColorType : 'linear'
        });
      }
      // save linear or radial with only one color as solid
      if (color.colorType !== 'solid' && color.palette.length === 1) {
        setColorPickerData(colorOptions.find((option) => option.id === 'solid'));
        handleDataChange({
          colorType: 'solid'
        });
      }
      setColorPickerOpened(false);
    },
    [colorpickerButtonRef]
  );
  return (
    <>
      {!colorPickerOpened && (
        <StyledFlexSpaceBetween className='flex-center-top-margin'>
          <StyledColorPickerBackground>
            <img
              style={{
                height: '23px',
                width: '23px',
                position: 'absolute',
                borderRadius: '3px'
              }}
              alt='Zuuvi grid icon'
              src={Grid}
            />

            <StyledColorPickerButton
              ref={colorpickerButtonRef}
              type='button'
              className='color-picker-thumb'
              style={{
                position: 'relative',
                backgroundColor: createColorString(color),
              }}
              id='right-submenu-edit-open-background-colorpicker-border'
              onClick={handleClick}></StyledColorPickerButton>
          </StyledColorPickerBackground>
          <p className='input-text'>{colorPickerData.title}</p>
        </StyledFlexSpaceBetween>
      )}

      {colorPickerOpened && (
        <StyledChromePickerWrapper ref={colorPickerRef}>
          <StyledSelectWide
            id='select-gradient-solid-colorpicker'
            onChange={(e) => {
              handleDataChange({ colorType: e.target.value });
              setColorPickerData(colorOptions.find((option) => option.id === e.target.value));
            }}
            defaultValue={colorPickerData.id}>
            {colorOptions.map((variant) => (
              <option value={variant.id} key={variant.id}>
                {variant.title}
              </option>
            ))}
          </StyledSelectWide>

          <GradientColorPicker
            colorPickerData={colorPickerData}
            onHandleDataChange={handleDataChange}
            color={color}
          />
        </StyledChromePickerWrapper>
      )}
    </>
  );
}
ColorPicker.defaultProps = {
  color: {
    palette: [{ color: 'rgb(255,255,255)', opacity: 1, offset: 0, id: 1, active: true }],
    angle: 90,
    colorType: 'solid'
  }
};

ColorPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  color: PropTypes.object
};
export default ColorPicker;
