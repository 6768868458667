export default function convertRGBAToHex(rgba, includeAlpha = true) {
  const rgbaRegex = /rgba?\((\d{1,3}) *?,(\d{1,3}) *?,(\d{1,3}) *?(?:,(\d+(?:\.\d+)?))?\)/;
  const parts = rgba.replaceAll(' ', '').match(rgbaRegex);
  if (parts) {
    const r = parseInt(parts[1]).toString(16).padStart(2, '0');
    const g = parseInt(parts[2]).toString(16).padStart(2, '0');
    const b = parseInt(parts[3]).toString(16).padStart(2, '0');
    let hex = `#${r}${g}${b}`;
    if (includeAlpha && parts[4] !== undefined && parts[4] !== 1) {
      const a = Math.round(parseFloat(parts[4]) * 255)
        .toString(16)
        .padStart(2, '0');
      hex += a;
    }
    hex = hex.toUpperCase();
    return hex;
  }
  return rgba;
}
