import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  background: '#272727',
  overflow: 'visible',
  language: 'en',
  grid: false,
  guidelines: false,
  ruler: false
};

const userSettings = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    getUserSettings() {},
    setUserSettings(state, action) {
      const { payload } = action;
      return payload;
    },
    updateUserSettings() {}
  }
});

export const { getUserSettings, setUserSettings, updateUserSettings } = userSettings.actions;
export default userSettings.reducer;
