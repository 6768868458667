import { takeEvery, call, put } from 'redux-saga/effects';
import * as api from '../../api';
import { getUserSettings, setUserSettings, updateUserSettings } from '../slices/userSettings';

export function* handleGetUserSettings(action) {
  const userId = action.payload;
  try {
    const { data } = yield call(api.userSettingsService.getUserSettings, userId);
    yield put(setUserSettings(data));
  } catch (e) {
    yield put(
      setUserSettings({
        background: '#272727',
        overflow: 'visible',
        language: 'en',
        grid: true,
        guidelines: true,
        ruler: true
      })
    );
    // console.log('catch');
  }
}

export function* handleUpdateUserSettings(action) {
  const { userId, settingsType, changeFrom, changeTo } = action.payload;
  const response = yield call(
    api.userSettingsService.updateUserSettings,
    userId,
    settingsType,
    changeFrom,
    changeTo
  );

  yield put(setUserSettings(response));
}

function* watchUserSettings() {
  yield takeEvery(getUserSettings, handleGetUserSettings);
  yield takeEvery(updateUserSettings, handleUpdateUserSettings);
}

export default watchUserSettings;
