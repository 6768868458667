import styled, { css } from 'styled-components';

const sharedStyle = css`
  background-color: #313131;
  border: 1px solid #747474;
  height: 23px;
`;

const sharedPadding = css`
  padding-left: 10px;
`;

export const StyledInput = styled.input`
  text-align: ${(props) => (props.leftAligned ? 'left' : 'right')};
  height: 23px;
  font-size: 12px;
  padding: 0px;
  width: 100%;
  background: ${(props) => (props.disabled ? '#2b2b2b' : '#313131')};
  outline: none;
  border: none;
  color: ${(props) => (props.disabled ? '#C0C0C0' : 'white')};
`;

export const StyledInputWrapperSmall = styled.div`
  ${sharedStyle};
  margin-bottom: 0px;
  width: 23px;
  text-align: center;
  > svg {
    height: 100%;
  }
`;

export const StyledInputLabelWrapper = styled.div`
  width: ${(props) => (props.autoWidth ? 'auto' : ' 100%')};
  margin-bottom: ${(props) => (props.removedBottomMargin ? '0px' : ' 17px')};
  > p {
    margin: ${(props) => (props.templateSettings ? ' 0px 0px 5px 0px' : '0px 0px 10px 0px')};
    font-size: 14px;
  }
`;

export const StyledOpacityInputWrapper = styled.div`
  align-items: center;
  display: flex;
  > div {
    min-width: 30px;
    width: 30px;
    text-align: right;
  }
`;

export const StyledInputWrapperBig = styled.div`
  ${sharedStyle};
  ${sharedPadding};
  color: ${(props) => (props.disabled ? '#C0C0C0' : 'white')};
  margin-bottom: ${(props) =>
    props.removedBottomMargin ? '0px' : props.bigBottomMargin ? '18px' : '8px'};
  border-radius: 4px;
  padding-right: 5px;
  width: ${(props) => (props.fullWidth ? '219px' : '83px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  > input {
    background: none;
  }
`;

export const StyledSelectWide = styled.select`
  ${sharedStyle};
  ${sharedPadding};
  margin-bottom: 8px;
  width: 100%;
  outline: none;
  color: white;
  font-size: 12px;
  align-self: center;
  height: 25px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #313131
    url("data:image/svg+xml;utf8,
     <svg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M5.92729 0.177677L3.49687 2.53455L1.06644 0.177677C0.822148 -0.0592255 0.427517 -0.0592255 0.183221 0.177677C-0.0610738 0.414579 -0.0610738 0.797267 0.183221 1.03417L3.05839 3.82232C3.30269 4.05923 3.69732 4.05923 3.94161 3.82232L6.81678 1.03417C7.06107 0.797267 7.06107 0.414579 6.81678 0.177677C6.57248 -0.0531511 6.17159 -0.0592255 5.92729 0.177677Z'
        fill='silver'
        />
      </svg>")
    no-repeat;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
`;

export const StyledFlexSpaceBetween = styled.div`
  width: ${(props) => (props.boxWidth ? props.boxWidth : '100px;')};
  display: flex;
  height: ${(props) => (props.height ? props.height : '25px')};
  justify-content: ${(props) => (props.textalignment ? 'space-between' : 'initial')};
  margin-bottom: ${(props) => (props.bottomMargin ? props.bottomMargin : '0px')};
  margin-top: ${(props) => (props.colorpicker ? '10px' : '0px')};
  align-items: ${(props) => (props.alignTop ? 'initial' : 'center')};
`;

export const StyledFlexCenter = styled.div`
  width: ${(props) => (props.boxWidth ? props.boxWidth : '100px;')};
  display: flex;
  height: ${(props) => (props.height ? props.height : '25px')};
  justify-content: center;
  margin-bottom: ${(props) => (props.bottomMargin ? props.bottomMargin : '0px')};
  margin-top: ${(props) => (props.colorpicker ? '10px' : '0px')};
  align-items: ${(props) => (props.alignTop ? 'initial' : 'center')};
`;

export const StyledChromePickerWrapper = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.removedTopMargin ? '0px' : '8px')};
  > .chrome-picker {
    width: 100% !important;
    margin-bottom: 8px;
  }
`;
export const GradientPickerWrapper = styled.div`
  > .ap {
    flex: none;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #d2d5dc;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .ap .apc {
    width: 6px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  > .chrome-picker {
    width: 100% !important;
    margin-bottom: 8px;
  }
`;
export const StyledColorPickerBackground = styled.div`
  position: relative;
  height: 23px;
  width: 23px;
  margin-right: 10px;
  background-color: transparent;
  border: 1px solid #747474;
  border-radius: 4px;
`;

export const StyledColorPickerButton = styled.button`
  height: 23px;
  width: 23px;
  position: relative;
  border-radius: 3px;
`;

export const StyledCheckbox = styled.input`
  height: 15px;
  cursor: pointer;
  width: 15px;
  margin: 0px 10px 0px 0px;
`;

export const StyledTextarea = styled.textarea`
  background: #313131;
  height: ${(props) => (props.small ? '21px' : '95px')};
  overflow: auto;
  min-width: 222px;
  max-width: 222px;
  min-height: ${(props) => (props.small ? '21px' : '95px')};
  color: white;
  border-radius: 4px;
  padding: 6px;
  line-height: 20px;
  font-size: ${(props) => (props.smallText ? '12px' : '14px')};
  outline: none;
  font-family: 'Roboto';
`;

export const StyledSpan = styled.span`
  margin-left: 2px;
`;
