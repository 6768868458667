import { useEffect, useState, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import DpaStepsComponentWrapper from '../common/styled/DpaStepsComponentWrapper';
import {
  MagnifierGlassICon,
  CloseDpaIcon,
  RightSmallArrowIcon,
  LeftSmallArrowIcon
} from '../../assets/icons';
import {
  generateDpaFeed,
  resetEnlargedImage,
  setEnlargedImage,
  getFeedImagesStatus
} from '../../redux/slices/dpa';
import ZuuviLoader from '../common/ZuuviLoader';
import { useClickOutside } from '../../assets/utils';
import UseKeyboardShortcut from '../../../HotkeyHandler';
import { useIntersection } from '../../../utils/hooks/useIntersection';

const ScreenshotOverviewWrapper = styled.div`
  width: 456px;
  height: 357px;
  margin: 6px auto;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    display: block;
  }

  &::-webkit-scrollbar-track {
    margin: 2px;
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 10px;
  }
`;

const Screenshot = styled.div`
  ${(props) =>
    ['img-id-0', 'img-id-1', 'img-id-2'].includes(props.id)
      ? 'margin: 0px 8px 8px 8px;'
      : 'margin: 8px;'}

  height: 134px;
  width: 134px;
  object-fit: contain;
  border: 1px solid #f2f2f2;
  cursor: pointer;

  ${(props) =>
    props.isEnlarged &&
    `position: absolute;
    top: 62px;
    left: 188px;
    z-index: 600;
    height: 475px;
    width: 475px;
    cursor: default;
    object-fit: contain;`}
`;

const DpaCopyLinkBox = styled.div`
  padding: 8px;
  font-weight: bold;
  width: 440px;
  margin: 22px auto 0;
  color: #4a90e2;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;
const DpaCopyLink = styled.input`
  width: 320px;
  margin: auto 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #f2f2f2;
  color: #4a90e2;
  &::placeholder {
    color: #4a90e2;
  }
`;
const CopyButton = styled.button`
  width: 100px;
  height: 26px;
  border 1px solid #4A90E2;
  color:#4A90E2;
  margin-left:3px;
  border-radius: 8px;
  cursor:pointer;
  > p {
    margin: 0;
  }
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 0;
  transition: all ease-in-out 0.5s;
`;

const Media = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  height: 100%;
  width: 100%;

  ${(props) =>
    !props.isEnlarged &&
    `
    &:hover {
    opacity: 1;
    ${Overlay}{
      opacity: 0.4
    }
    > img {
      transform:  scale(1.1);
      filter:  blur(2px);
    }

  }
  `};
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: -1;
  margin: auto;
  background: white;
`;
const ImageLoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #c6c6c6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
`;

const ImagesStatusStringDiv = styled.div`
  weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #8b8b8b;
`;

const leftArrowIconStyle = {
  width: '30px',
  height: '30px',
  position: 'absolute',
  top: '202',
  left: '-50',
  padding: '10px',
  zIndex: 1000,
  cursor: 'pointer'
};

const rightArrowIconStyle = {
  width: '30px',
  height: '30px',
  position: 'absolute',
  top: '202',
  right: '-50',
  zIndex: 1000,
  padding: '10px',
  cursor: 'pointer'
};

const closeIconStyle = {
  position: 'absolute',
  top: '10',
  right: '10',
  zIndex: 1000,
  cursor: 'pointer'
};

const ScreenshotImage = ({ src, alt, index, wrapperRef }) => {
  const screenshotRef = useRef();
  const screenshotWrapperRef = useRef(); // we need this to scroll

  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);
  const { enlargedImage, numberOfImages } = useSelector((state) => state.dpa);
  const shouldShowImage = useIntersection(screenshotRef, wrapperRef, '300px');

  const isEnlarged = enlargedImage === index;

  function closeEnlargedImage() {
    if (index === enlargedImage) {
      dispatch(resetEnlargedImage());
    }
  }

  useClickOutside(screenshotRef, () => {
    closeEnlargedImage();
  });

  useEffect(() => {
    if (isEnlarged) {
      screenshotWrapperRef.current.scrollIntoView();
    }
  }, [enlargedImage]);

  return (
    <div style={{ width: '33%' }} ref={screenshotWrapperRef}>
      <Screenshot
        id={`img-id-${index}`}
        key={`img-key-${index}`}
        isEnlarged={isEnlarged}
        ref={screenshotRef}>
        {isEnlarged && (
          <>
            <CloseDpaIcon
              onClick={() => {
                closeEnlargedImage();
              }}
              style={closeIconStyle}
            />

            {index !== numberOfImages - 1 && (
              <RightSmallArrowIcon
                onClick={() => {
                  dispatch(setEnlargedImage(index + 1));
                }}
                style={rightArrowIconStyle}
              />
            )}

            {index !== 0 && (
              <LeftSmallArrowIcon
                onClick={() => {
                  dispatch(setEnlargedImage(index - 1));
                }}
                style={leftArrowIconStyle}
              />
            )}
          </>
        )}

        <Media
          isEnlarged={isEnlarged}
          onClick={() => {
            dispatch(setEnlargedImage(index));
          }}>
          {shouldShowImage && (
            <>
              {!isLoaded && (
                <ImageLoaderWrapper>
                  <ZuuviLoader size='mini' color='white' />
                </ImageLoaderWrapper>
              )}
              <Overlay style={{ ...((!isLoaded || isEnlarged) && { display: 'none' }) }}>
                <MagnifierGlassICon style={{ position: 'absolute' }} />
              </Overlay>
              <Image
                src={src}
                onLoad={(e) => {
                  setIsLoaded(true);
                }}
                alt={alt}
              />
            </>
          )}
        </Media>
      </Screenshot>
    </div>
  );
};

function FinalOverview() {
  const { clientId, subaccountId, campaignId, adId, adVersionId, adWidth, adHeight } = useParams();
  const wrapperRef = useRef();
  const dispatch = useDispatch();
  const dpaState = useSelector((state) => state.dpa);
  const [copyButtonText, setCopyButtonText] = useState('Copy link');

  const imageString = `${window._env_.REACT_ENV_GET_SCREENSHOT_URL}${
    window._env_.REACT_ENV_GET_BANNER_URL
  }${clientId}/${subaccountId}/${campaignId}/${adId}/${adVersionId}/preview/index.html&height=${adHeight}&width=${adWidth}&seekTime=${
    dpaState.seekTime * 1000
  }&dcoRow=`;

  const imagesAmount = useSelector((state) => state.dpa.numberOfImages);
  const imageRows = useSelector((state) => state.dpa.imageRows);
  const imagesReadyCount = useSelector((state) => state.dpa.imagesReadyCount);

  const [imagesArray, setImagesArray] = useState([]);

  useEffect(() => {
    const newArr = [];
    for (const i of imageRows) {
      newArr.push(`${imageString}${i}`);
    }
    setImagesArray(newArr);
  }, [imageRows]);
  const copyButtonRef = useRef();

  const initalDpaFeedLink = 'Creating link ...';

  useEffect(() => {
    dispatch(generateDpaFeed());
  }, []);

  const imageStatusInterval = useRef(null);
  useEffect(() => {
    imageStatusInterval.current = setInterval(() => dispatch(getFeedImagesStatus()), 2500);
    return () => {
      clearTimeout(imageStatusInterval.current);
    };
  }, [imagesAmount]);

  useEffect(() => {
    if (imagesReadyCount >= imagesAmount) {
      clearTimeout(imageStatusInterval.current);
    }
  }, [imagesAmount, imagesReadyCount]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copyButtonText === 'Copied') {
        setCopyButtonText('Copy link');
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [copyButtonText]);

  UseKeyboardShortcut(
    ['ArrowLeft'],
    () => {
      dispatch(setEnlargedImage(dpaState.enlargedImage - 1 >= 0 ? dpaState.enlargedImage - 1 : 0));
    },
    {
      overrideSystem: true,
      overrideContentEditable: true
    }
  );

  UseKeyboardShortcut(
    ['ArrowRight'],
    () =>
      dispatch(
        setEnlargedImage(
          dpaState.enlargedImage + 1 < imagesAmount - 1
            ? dpaState.enlargedImage + 1
            : imagesAmount - 1
        )
      ),
    {
      overrideSystem: true,
      overrideContentEditable: true
    }
  );

  UseKeyboardShortcut(['Escape'], () => dispatch(resetEnlargedImage()), {
    overrideSystem: true,
    overrideContentEditable: true
  });

  return (
    <DpaStepsComponentWrapper>
      {dpaState.dpaUrl !== '' ? (
        <>
          <ScreenshotOverviewWrapper ref={wrapperRef}>
            {/* add slow load */}
            {imagesAmount !== 0 ? (
              <>
                {imagesArray.map((src, i) => (
                  <ScreenshotImage src={src} alt='dpa-image' index={i} wrapperRef={wrapperRef} />
                ))}
              </>
            ) : (
              <span
                style={{
                  width: '100%',
                  alignSelf: 'center',
                  textAlign: 'center',
                  color: '#828282',
                  fontSize: '13px'
                }}>
                You exported a feed without any rows. <br />
                If your source feed changes, the feed will automatically be updated.
              </span>
            )}
          </ScreenshotOverviewWrapper>
          <ImagesStatusStringDiv>
            {imagesReadyCount} of {imagesAmount} images are ready
          </ImagesStatusStringDiv>
          <DpaCopyLinkBox>
            <DpaCopyLink
              readonly='readonly'
              initalDpaFeedLink={initalDpaFeedLink}
              value={dpaState.dpaUrl}
              placeholder='Creating link ...'
            />
            <CopyButton
              onClick={() => {
                setCopyButtonText('Copied');
                navigator.clipboard.writeText(dpaState.dpaUrl);
              }}>
              <p ref={copyButtonRef}>{copyButtonText}</p>
            </CopyButton>
          </DpaCopyLinkBox>
        </>
      ) : (
        <div
          style={{
            left: '50%',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            marginTop: '25%'
          }}>
          <ZuuviLoader size='mini' />
        </div>
      )}
    </DpaStepsComponentWrapper>
  );
}

export default FinalOverview;
